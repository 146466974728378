import React from 'react';
import {FormControl, FormHelperText, InputLabel, Select, styled} from '@mui/material';

const CustomSelect = styled((props) =>
    <FormControl fullWidth error={props?.error}>
        <InputLabel id={props.id + '-label'}>{props.label}</InputLabel>
        <Select labelId={props.id + '-label'} {...props} />
        <FormHelperText>{props?.helperText}</FormHelperText>
    </FormControl>)();

export default CustomSelect;
