import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Stack} from "@mui/system";
import {useTranslate} from "../../translations/translate";

const VerifyAccount = () => {
    const navigate = useNavigate();
    const translate = useTranslate();

    const handleOpenEmail = () => {
        navigate('/login');
    };

    return (
        <Box p={4} sx={{zIndex: '2', maxWidth: 500, width: '100%'}}>
            <Typography variant="headingConsole" color={"secondary"} gutterBottom>
                {translate('verify_account')}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{fontSize: 35, lineHeight: 1.2}}>
                {translate('verify_account_check_email')}
            </Typography>
            <Stack direction="row" spacing={2} mt={3} justifyContent="flex-end">
                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleOpenEmail}
                >
                    {translate('back_to_login')}
                </Button>
            </Stack>
        </Box>
    );
};

export default VerifyAccount;
