import React from "react";
import {Box,} from '@mui/material';
import {observer} from "mobx-react-lite";

import AuthRegisterEntity from '../../views/authentication/authForms/AuthRegisterEntity'
import {useTranslate} from "../../translations/translate";

const RegisterEntity = observer(() => {
    const translate = useTranslate();
    
    return (
        <Box p={4} sx={{zIndex: '2', maxWidth: 500, width: '100%'}}>
            <AuthRegisterEntity
                title={translate('add_entity_details')}
            />
        </Box>
    );
});

export default RegisterEntity;
