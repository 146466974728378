export const SUPER_MANAGER_PERMISSION = 0;
export const MANAGER_PERMISSION = 1;
export const PROFESSIONAL_PERMISSION = 2;

/**
 * This constant is used to check if the application is running in an Electron environment or in a browser.
 * It's essential to determine the app's behavior and segregate the code that should run in each environment.
 */
export const isElectron = navigator.userAgent.includes('Electron');

export const LANGUAGES = [
    {
        value: 'en',
        label: 'English',
        icon: '/files/flag/en.svg',
    },
    {
        value: 'pt',
        label: 'Português',
        icon: '/files/flag/pt.svg',
    },
    {
        value: 'es',
        label: 'Español',
        icon: '/files/flag/es.svg',
    },
    {
        value: 'fr',
        label: 'Français',
        icon: '/files/flag/fr.svg',
    },
    {
        value: 'de',
        label: 'Deutsch',
        icon: '/files/flag/de.svg',
    },
];
export const PT_LANGUAGE = ['pt-PT', 'pt-BR'];

export const USERNAME_REGEX = /^[\w.]+$/;

export const RIGHT_HAND = 0;
export const LEFT_HAND = 1;

export const EXHAUSTION = [
    {
        value: 0,
        label: '<1 day/week',
    },
    {
        value: 1,
        label: '1-2 days/week',
    },
    {
        value: 2,
        label: '3-4 days/week',
    },
    {
        value: 3,
        label: '>4 days/week',
    },
];

export const PHYSICAL_ACTIVITY_OPTIONS = [
    {
        id: 1,
        label: 'walking_pleasure',
        display_name: 'walking_pleasure',
        energetic_spent: 3,
    },
    {
        id: 2,
        label: 'walking_work',
        display_name: 'walking_work',
        energetic_spent: 3,
    },
    {
        id: 3,
        label: 'walking_during_work',
        display_name: 'walking_during_work',
        energetic_spent: 3,
    },
    {
        id: 4,
        label: 'mowing_the_lawn_ridding',
        display_name: 'mowing_the_lawn_ridding',
        energetic_spent: 3,
    },
    {
        id: 5,
        label: 'mowing_the_lawn_walking',
        display_name: 'mowing_the_lawn_walking',
        energetic_spent: 5,
    },
    {
        id: 6,
        label: 'mowing_the_lawn_pushing',
        display_name: 'mowing_the_lawn_pushing',
        energetic_spent: 6,
    },
    {
        id: 7,
        label: 'raking',
        display_name: 'raking',
        energetic_spent: 3,
    },
    {
        id: 8,
        label: 'gardening',
        display_name: 'gardening',
        energetic_spent: 5,
    },
    {
        id: 9,
        label: 'hiking',
        display_name: 'hiking',
        energetic_spent: 6,
    },
    {
        id: 10,
        label: 'jogging',
        display_name: 'jogging',
        energetic_spent: 6,
    },
    {
        id: 11,
        label: 'biking',
        display_name: 'biking',
        energetic_spent: 3,
    },
    {
        id: 12,
        label: 'cycling',
        display_name: 'cycling',
        energetic_spent: 3,
    },
    {
        id: 13,
        label: 'dancing',
        display_name: 'dancing',
        energetic_spent: 5,
    },
    {
        id: 14,
        label: 'aerobic',
        display_name: 'aerobic',
        energetic_spent: 6,
    },
    {
        id: 15,
        label: 'bowling',
        display_name: 'bowling',
        energetic_spent: 3,
    },
    {
        id: 16,
        label: 'golf_power_cart',
        display_name: 'golf_power_cart',
        energetic_spent: 3,
    },
    {
        id: 17,
        label: 'golf_walking_cart',
        display_name: 'golf_walking_cart',
        energetic_spent: 3,
    },
    {
        id: 18,
        label: 'gold_clubs',
        display_name: 'gold_clubs',
        energetic_spent: 5,
    },
    {
        id: 19,
        label: 'single_tennis',
        display_name: 'single_tennis',
        energetic_spent: 6,
    },
    {
        id: 20,
        label: 'doubles_tennis',
        display_name: 'doubles_tennis',
        energetic_spent: 6,
    },
    {
        id: 21,
        label: 'racket_ball',
        display_name: 'racket_ball',
        energetic_spent: 6,
    },
    {
        id: 22,
        label: 'calisthenics',
        display_name: 'calisthenics',
        energetic_spent: 3,
    },
    {
        id: 23,
        label: 'swimming',
        display_name: 'swimming',
        energetic_spent: 6,
    },
];

export const SARCOPENIA_OPTIONS_BY_QUESTION_INDEX = [
    // Question 1
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_or_unable',
            score: 2,
        },
    ],

    // Question 2
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_use_aids_or_unable',
            score: 2,
        },
    ],

    // Question 3
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_or_unable_without_help',
            score: 2,
        },
    ],

    // Question 4
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_some',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_a_lot_or_unable',
            score: 2,
        },
    ],

    // Question 5
    [
        {
            value: 1,
            label: 'sarc_f_none',
            score: 0,
        },
        {
            value: 2,
            label: 'sarc_f_1_3_falls',
            score: 1,
        },
        {
            value: 3,
            label: 'sarc_f_4_or_more_falls',
            score: 2,
        },
    ],
];


export const FOLD_FRONT = 1;
export const FOLD_BACK = 2;

export const FOLDS6 = [0, 1, 4, 5, 6, 7];
export const FOLDS8 = [0, 1, 2, 3, 4, 5, 6, 7];

export const FOLDS = {
    0: {
        id: '0',
        originalName: 'fold_triceps',
        name: 'fold_triceps',
        description: 'fold_triceps_desc',
        bodyX: 830,
        bodyY: 560,
        type: FOLD_BACK,
    },
    1: {
        id: '1',
        originalName: 'fold_subscapular',
        name: 'fold_subscapular',
        description: 'fold_subscapular_desc',
        bodyX: 620,
        bodyY: 450,
        type: FOLD_BACK,
    },
    2: {
        id: '2',
        originalName: 'fold_biceps',
        name: 'fold_biceps',
        description: 'fold_biceps_desc',
        bodyX: 265,
        bodyY: 560,
        type: FOLD_FRONT,
    },
    3: {
        id: '3',
        originalName: 'fold_iliacCrest',
        name: 'fold_iliacCrest',
        description: 'fold_iliacCrest_desc',
        bodyX: 740,
        bodyY: 750,
        type: FOLD_BACK,
    },
    4: {
        id: '4',
        originalName: 'fold_supraspinale',
        name: 'fold_supraspinale' /* Previously known as the Suprailiac site */,
        description: 'fold_supraspinale_desc',
        bodyX: 730,
        bodyY: 900,
        type: FOLD_FRONT,
    },
    5: {
        id: '5',
        originalName: 'fold_abdominal',
        name: 'fold_abdominal',
        description: 'fold_abdominal_desc',
        bodyX: 470,
        bodyY: 700,
        type: FOLD_FRONT,
    },
    6: {
        id: '6',
        originalName: 'fold_anteriorThigh',
        name: 'fold_anteriorThigh',
        description: 'fold_anteriorThigh_desc',
        bodyX: 350,
        bodyY: 1350,
        type: FOLD_FRONT,
    },
    7: {
        id: '7',
        originalName: 'fold_medialcalf',
        name: 'fold_medialcalf',
        description: 'fold_medialcalf_desc',
        bodyX: 740,
        bodyY: 1775,
        type: FOLD_BACK,
    },
    8: {
        id: '8',
        originalName: 'fold_cheek',
        name: 'fold_cheek',
        description: 'fold_cheek_desc',
        bodyX: 390,
        bodyY: 50,
        type: FOLD_FRONT,
    },
    9: {
        id: '9',
        originalName: 'fold_chin',
        name: 'fold_chin',
        description: 'fold_chin_desc',
        bodyX: 530,
        bodyY: 140,
        type: FOLD_FRONT,
    },
    10: {
        id: '10',
        originalName: 'fold_chest',
        name: 'fold_chest',
        description: 'fold_chest_desc',
        bodyX: 420,
        bodyY: 440,
        type: FOLD_FRONT,
    },
    11: {
        id: '11',
        originalName: 'fold_umbilical',
        name: 'fold_umbilical',
        description: 'fold_umbilical_desc',
        bodyX: 550,
        bodyY: 850,
        type: FOLD_FRONT,
    },
    12: {
        id: '12',
        originalName: 'fold_knee',
        name: 'fold_knee',
        description: 'fold_knee_desc',
        bodyX: 720,
        bodyY: 1600,
        type: FOLD_FRONT,
    },
    13: {
        id: '13',
        originalName: 'fold_midAxillary',
        name: 'fold_midAxillary',
        description: 'fold_midAxillary_desc',
        bodyX: 370,
        bodyY: 560,
        type: FOLD_BACK,
    },
    14: {
        id: '14',
        originalName: 'fold_lowerBack',
        name: 'fold_lowerBack',
        description: 'fold_lowerBack_desc',
        bodyX: 450,
        bodyY: 850,
        type: FOLD_BACK,
    },
    15: {
        id: '15',
        originalName: 'fold_calf',
        name: 'fold_calf',
        description: 'fold_calf_desc',
        bodyX: 350,
        bodyY: 2000,
        type: FOLD_BACK,
    },
    16: {
        id: '16',
        originalName: 'fold_axillar',
        name: 'fold_axillar',
        description: 'fold_axillar_desc',
        bodyX: 770,
        bodyY: 440,
        type: FOLD_FRONT,
    },
};
