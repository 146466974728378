const LightThemeColors = [
    {
        name: 'ORANGE_THEME',
        palette: {
            primary: {
                main: '#FF5722',
                light: '#FBF2EF',
                dark: '#D84315',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#353767',
                light: '#ebf3fe',
                dark: '#292A4C',
                contrastText: '#ffffff',
            },
        },
    },
];

export {LightThemeColors};
