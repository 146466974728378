import React from "react";
import {Box} from '@mui/material';
import {observer} from "mobx-react-lite";
import AuthResetPassword from "../../views/authentication/authForms/AuthResetPassword";
import {useTranslate} from "../../translations/translate";

const ResetPassword = observer(() => {
    const translate = useTranslate();
    
    return (
        <Box p={4} sx={{zIndex: '2', maxWidth: 500, width: '100%'}}>
            <AuthResetPassword
                title={translate('reset_password')}
            />
        </Box>
    );
});

export default ResetPassword;

