import {useContext} from 'react';
import {LanguageContext} from '../contexts/LanguageContext';

export function useTranslate() {
    const {translations} = useContext(LanguageContext);

    const translate = (key, options = {}) => {
        let result = translations[key] || key;

        Object.entries(options).forEach(([placeholder, value]) => {
            const placeholderRegex = new RegExp(`{{${placeholder}}}`, 'g');
            result = result.replace(placeholderRegex, value);
        });

        return result;
    };

    return translate;
}
