import React from "react";
import {Box} from "@mui/material";
import {observer} from "mobx-react-lite";
import AuthRegisterUser from "../../views/authentication/authForms/AuthRegisterUser";
import {useTranslate} from "../../translations/translate";

const RegisterUser = observer(() => {
    const translate = useTranslate();
    return (
        <Box p={4} sx={{zIndex: '2', maxWidth: 500, width: '100%'}}>
            <AuthRegisterUser
                title={translate('add_your_details')}
            />
        </Box>
    );
});

export default RegisterUser;
