import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    MenuItem,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material';
import * as Yup from "yup";
import {Link, useNavigate} from "react-router-dom";

import CustomCheckbox from '../../../components/forms/theme-elements/CustomCheckbox';
import {Form, FormikProvider, useFormik} from "formik";
import {stores} from "../../../stores";
import CustomSelect from "../../../components/forms/theme-elements/CustomSelect";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTranslate} from "../../../translations/translate";


const AuthSelectEntity = ({title, subtitle}) => {
    const navigate = useNavigate();
    const translate = useTranslate();
    const [showSelectEntityError, setSelectEntityError] = useState(false);

    const SelectEntitySchema = Yup.object().shape({
        entity: Yup.number().required(translate("yup_entity_required")),
        remember: Yup.boolean(),
    });

    useEffect(() => {
        if (stores.userStore.entities.length === 0) {
            navigate("/register-entity");
        } 
        // else if (stores.userStore.entities.length === 1 && stores.userStore.entityId === null) {
        //     stores.userStore.selectEntity({entity: stores.userStore.entities[0].id})
        //         .then(result => {
        //             if (result) {
        //                 navigate("/");
        //             } else {
        //                 setSelectEntityError(true);
        //             }
        //         })
        // }
    }, []);


    const formik = useFormik({
        initialValues: {
            entity: stores.userStore.entityId || '',
            remember: false,
        },
        validationSchema: SelectEntitySchema,
        onSubmit: (values, {setSubmitting}) => {
            stores.userStore
                .selectEntity(values)
                .then(result => {
                    switch (result) {
                        case true:
                            navigate("/", {replace: true});
                            break;
                        case false:
                        case null:
                            setSelectEntityError(true);
                            break;
                        default:
                            console.error('Unexpected result:', result);
                    }
                })
                .catch(error => {
                    console.error('Error during login:', error);
                    setSelectEntityError(true);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const {
        errors,
        touched,
        values,
        getFieldProps,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
    }
        = formik;

    return (
        <>
            <Stack direction="column" spacing={1} mb={1}>
                {title ? (
                    <Typography variant="headingConsole" color={"secondary"}>
                        {title}
                    </Typography>
                ) : null}
                <Typography
                    variant='headingConsoleSubtitle' color={"secondary"}
                >
                    {translate('select_your_entity')}
                </Typography>
            </Stack>
            {showSelectEntityError &&
                <Alert variant="filled" severity='error' my={2}>{translate('error_selecting_entity')}</Alert>}

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack>
                        <Box sx={{marginBottom: '5px', marginTop: '25px'}}>
                            <CustomSelect
                                id="entity"
                                label={translate('entity')}
                                {...getFieldProps('entity')}
                                value={values.entity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.entity && Boolean(errors.entity)}
                                helperText={touched.entity && errors.entity}
                                input={<OutlinedInput label={translate('entity')}/>}
                            >
                                <MenuItem disabled>
                                    <em>{translate('select_your_entity')}</em>
                                </MenuItem>
                                {stores.userStore.entities.map((option, index) => (
                                    <MenuItem key={index} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </Box>
                        <Stack justifyContent="space-between" direction="row" alignItems="center" mb={2}>
                            <FormGroup>
                                <FormControlLabel
                                    {...getFieldProps('remember')}
                                    control={<CustomCheckbox/>}
                                    label={translate('remember_this_option')}
                                />
                            </FormGroup>
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={2} mt={1} justifyContent="flex-end">
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="large"
                            onClick={() => {
                                stores.userStore.logout();
                                navigate("/login");
                            }}
                            type="button"
                        >
                            {translate('cancel')}
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            size="large"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type="submit"
                        >
                            {translate('confirm')}
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
            <Stack direction="row" spacing={1} mt={3} justifyContent="flex-end">
                <Typography
                    component={Link}
                    to="/register-entity"
                    fontWeight="500"
                    sx={{
                        textDecoration: 'none',
                        color: 'primary.main',
                    }}
                >
                    {translate('create_new_entity')}
                </Typography>
            </Stack>
            {subtitle}
        </>
    );
}
export default AuthSelectEntity;
