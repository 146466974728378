import {action, makeAutoObservable} from "mobx";
import agent from "../agent";

class UserStore {
    // FIXME: add the rest of the fields
    loggedIn = false;
    username = localStorage.getItem('username') || null;
    language = localStorage.getItem('language') || 'en';
    entityId = localStorage.getItem('entityId') || null;
    entityName = null;
    entityCountry = null;
    entities = []
    token = localStorage.getItem('token') || null;
    userType = 2;
    inProgress = {
        getEntities: false,
        resetPassword: false,
        login: false,
        getUserByUniqueId: false,
        register: false
    }
    error = null;
    userByUniqueId = {};
    currentUser = {};

    constructor() {
        makeAutoObservable(this, {
            pullEntities: action,
            login: action,
            backendLogin: action,
            setLoggedIn: action,
            setUsername: action,
            setLanguage: action,
            setEntityId: action,
            setEntityName: action,
            setEntityCountry: action,
            setCurrentEntity: action,
            setEntities: action,
            setToken: action,
            setError: action,
            setUserType: action,
            reset: action
        });
    }

    setLoggedIn(loggedIn) {
        this.loggedIn = loggedIn;
    }

    setUsername(username) {
        this.username = username;
    }

    setLanguage(language, updateDB) {
        this.language = language;
        localStorage.setItem('language', language);
    }

    setEntityId(remember, entityId) {
        if (remember)
            localStorage.setItem('entityId', entityId);
        this.entityId = entityId;
    }

    setEntityName(entityName) {
        this.entityName = entityName;
    }

    setEntityCountry(entityCountry) {
        this.entityCountry = entityCountry;
    }

    setCurrentEntity(entity) {
        this.setEntityId(true, entity.id);
        this.setEntityName(entity.name);
        this.setEntityCountry(entity.country);
    }

    setEntities(entities) {
        this.entities = entities;
    }

    setToken(remember, username, token) {
        if (remember)
            localStorage.setItem('username', username);
        localStorage.setItem('token', token);
        this.token = token;
    }

    setError(error) {
        this.error = error;
    }

    setUserType(userType) {
        this.userType = userType;
    }

    setCurrentUser(currentUser) {
        this.currentUser = currentUser;
    }

    reset() {
        localStorage.removeItem('entityId');
        localStorage.removeItem('token');
        this.loggedIn = false;
        this.username = null;
        this.language = 'en';
        this.entityId = null;
        this.entityName = null;
        this.entityCountry = null;
        this.entities = [];
        this.token = null;
        this.error = null;
        this.userByUniqueId = null;
        this.inProgress = {
            getEntities: false,
            resetPassword: false,
            login: false,
            getUserByUniqueId: false,
            register: false
        };
        this.userType = 2;
        this.currentUser = {};
    }

    async login(values) {
        this.inProgress.login = true;

        const username = values.username;
        const password = values.password;
        const remember = values.remember;

        return this.backendLogin(username, password, remember)
            .then(async (backendResult) => {
                if (backendResult) {
                    await this.pullCurrentUser();
                } else {
                    console.error('Invalid credentials');
                }
                return backendResult;
            })
    }

    async selectEntity(values) {
        this.inProgress.login = true;

        const entityId = values.entity;
        const remember = values.remember || false;

        return agent.Auth.selectEntity({entity_id: entityId})
            .then(
                action(response => {
                    if (response.status === 200) {
                        this.setEntityId(remember, entityId);
                        this.setEntityName(this.entities.find(entity => entity.id === entityId)?.name);
                        this.setEntityCountry(this.entities.find(entity => entity.id === entityId)?.country);
                    }
                    return true;
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.login = false;
                })
            );
    }

    async addEntity(entityData) {
        this.inProgress.login = true;

        return agent.Entity.add(entityData)
            .then(
                action(response => {

                    if (response.status === 201) {
                        const entity = response.body.entity
                        this.setCurrentEntity(entity);
                        this.selectEntity({entity: entity.id, remember: true});

                    }
                    return true;
                })
            )
            .catch(
                action(err => {
                    console.error("Error during entity registration:", err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.login = false;
                })
            );
    }


    async pullCurrentUser() {
        this.inProgress.login = true;

        return agent.User.current(this.userType)
            .then(
                action(response => {
                    this.setUsername(response.body.username);
                    this.setEntities(response.body?.entities);
                    this.setLanguage(response.body?.language || this.language);
                    this.setCurrentUser(response.body);
                    this.setLoggedIn(true);
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.login = false;
                })
            );
    }

    async loadAuthUser() {
        if (this.token) {
            return this.pullCurrentUser().then(() => {
                if (this.entityId) {
                    this.setEntityName(this.entities.find(entity => entity.id === this.entityId)?.name);
                    this.setEntityCountry(this.entities.find(entity => entity.id === this.entityId)?.country);
                }
                return this.currentUser;
            }).catch(err => {
                    console.error(err);
                    this.reset();
                    return null;
                }
            );
        } else {
            this.reset();
            return null;
        }
    }


    // offline fetch only returns the last entity that was used to login
    async pullEntities(username) {
        this.inProgress.getEntities = true;

        try {
            let response = await agent.Entity.list();
            if (response.body) {
                this.setEntities(response.body);
                return response.body;
            }
        } catch (error) {
            console.error('Error fetching entities:', error.message);
        } finally {
            this.inProgress.getEntities = false;
        }

        return [];
    }

    async backendLogin(username, password, remember) {
        try {
            let response = await agent.Auth.login({username: username, password: password});
            if (response.body?.token) {
                this.setToken(remember, username, response.body.token);
                return true;
            } else return false;
        } catch (error) {
            this.setError(error.response.body);
            return null;
        } finally {
            this.inProgress.login = false;
        }
    }

    async logout() {
        this.reset();
        return Promise.resolve();
    }

    async requestPasswordReset(username) {
        this.inProgress.resetPassword = true;
        await agent.Auth.passwordReset({username: username});
        this.inProgress.resetPassword = false;
    }

    async resetPassword(token, password) {
        this.inProgress.resetPassword = true;

        return agent.Auth.resetPassword({token: token, password: password})
            .then(
                action(response => {
                    if (response.status === 200) {
                        return true;
                    }
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                        this.inProgress.resetPassword = false;
                    }
                )
            );
    }

    pullUserByUniqueId = (uniqueIdCountry, uniqueIdNumber) => {
        this.inProgress.getUserByUniqueId = true;
        return agent.User.byUniqueId(uniqueIdCountry, uniqueIdNumber)
            .then(
                action(user => {
                    this.userByUniqueId = user;
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.getUserByUniqueId = false;
                })
            );
    };

    register = values => {
        this.inProgress.register = true;
        this.errors = undefined;

        let entity = this.currentUser?.current_entity ? this.currentUser?.current_entity.id : null;

        return agent.Auth.register({
            name: values.name,
            email: values.email,
            country: values.country.label,
            entity_id: entity
        })
            .then(
                action(response => {
                    if (response.status === 200) {
                        return true;
                    }
                })
            )
            .catch(
                action(err => {
                    this.errors = err.response;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.register = false;
                })
            );
    };

    confirmAccount = body => {
        return agent.Auth.confirmAccount(body)
            .then(
                action(response => {
                    if (response.status === 200) {
                        return true;
                    }
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            );
    }
}


export default UserStore;
