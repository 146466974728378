import {action, makeObservable, observable} from "mobx";
import agent from "../agent";

class PatientStore {
    all = []
    inProgress = {
        register: false,
        get: false,
        getAll: false,
        getAppointments: false,
        getAppointment: false,
    }

    constructor() {
        makeObservable(this, {
            all: observable,
            inProgress: observable,
        });
    }

    register = values => {
        this.inProgress.register = true;

        let body = {
            updated: [],
            deleted: [],
            last_sync: new Date().toISOString()
        };

        let person = JSON.parse(JSON.stringify(values));

        let patientData = {
            internal_id: person.internal_id || '',
            unique_id_number: person.unique_id_number || '',
            unique_id_country: person.unique_id_country?.code || '',
            association_date: new Date().toISOString(),
            name: person.name || '',
            email: person.email || '',
            phone_number: person.phone_number || '',
            birthdate: person.birthdate || '',
            gender: person.gender || '',
            ethnicity: '',
            address: {
                type: 1,
                street: person.address?.street || '',
                postal_code: person.address?.postal_code || '',
                locality: person.address?.locality || '',
                city: person.address?.city || '',
                state_province_region: person.address?.state_province_region || '',
                country: person.address?.country || '',
            }
        }

        body.updated.push(patientData);

        return agent.Client.add(body)
            .then(() => {
                agent.Client.list().then(
                    action(clients => {
                        this.all = clients;
                    })
                )
            })
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.register = false;
                })
            );
    }

    get = id => {
        this.inProgress.get = true;
        return agent.Client.get(id)
            .then(action(client => {
                return client.body;
            }))
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.get = false;
                })
            )
    }

    getAll = () => {
        this.inProgress.getAll = true;
        return agent.Client.list()
            .then(action(clients => {
                    this.all = clients.body;
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.getAll = false;
                })
            );
    };

    getAppointments = id => {
        this.inProgress.getAppointments = true;
        return agent.Client.listAppointments(id, 1, 100)
            .then(action(appointments => {
                return appointments.body;
            }))
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.getAppointments = false;
                })
            );
    }

    getAppointment = id => {
        this.inProgress.getAppointment = true;
        return agent.Client.getAppointment(id)
            .then(action(appointment => {
                return appointment.body;
            }))
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.getAppointment = false;
                })
            );

    }
}

export default PatientStore;
