import React, {createContext, useEffect, useState} from 'react';
import {stores} from '../stores';

export const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {
    const [language, setLanguage] = useState(stores.userStore.language);
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        const loadTranslations = async () => {
            const languageFile = await import(`../translations/${language}.js`);
            setTranslations(languageFile.default);
        };
        loadTranslations();
    }, [language]);

    const changeLanguage = (newLanguage) => {
        stores.userStore.setLanguage(newLanguage);
        setLanguage(newLanguage);
    };

    return (
        <LanguageContext.Provider value={{language, translations, changeLanguage}}>
            {children}
        </LanguageContext.Provider>
    );
};
