import React, {lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import Loadable from '../components/container/Loadable';
import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RegisterUser from "../pages/Auth/RegisterUser";
import RegisterCheckEmail from "../pages/Auth/RegisterCheckEmail";
import ConfirmUserAccount from "../pages/Auth/ConfirmUserAccount";
import SelectEntity from "../pages/Auth/SelectEntity";
import ResetPassword from "../pages/Auth/ResetPassword";
import RegisterEntity from "../pages/Auth/RegisterEntity";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const AuthLayout = Loadable(lazy(() => import('../layouts/AuthLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Notifications = Loadable(lazy(() => import('../pages/Notifications')));
const ProfessionalsList = Loadable(lazy(() => import('../pages/Professionals/ProfessionalsList')));
const ProfessionalsDetail = Loadable(lazy(() => import('../pages/Professionals/ProfessionalsDetail')));
const AddProfessional = Loadable(lazy(() => import('../pages/Professionals/AddProfessional')));
const PatientsList = Loadable(lazy(() => import('../pages/Patients/PatientsList')));
const PatientsDetail = Loadable(lazy(() => import('../pages/Patients/PatientsDetail')));
const AddPatient = Loadable(lazy(() => import('../pages/Patients/AddPatient')));

// authentication
const Login = Loadable(lazy(() => import('../pages/Auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/Auth/ForgotPassword')));
const Error = Loadable(lazy(() => import('../views/pages/Error')));
const Maintenance = Loadable(lazy(() => import('../views/pages/Maintenance')));

const Router = () => (

    <Routes>
        <Route path="/" element={<FullLayout/>}>
            <Route path="/" element={<Navigate to="/dashboard"/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/notifications" element={<Notifications/>}/>
            <Route path="/professionals" element={<ProfessionalsList/>}/>
            <Route path="/professionals/:id" element={<ProfessionalsDetail/>}/>
            <Route path="/professionals/add" element={<AddProfessional/>}/>
            <Route path="/patients" element={<PatientsList/>}/>
            <Route path="/patients/:id" element={<PatientsDetail/>}/>
            <Route path="/patients/add" element={<AddPatient/>}/>
        </Route>
        <Route path="/" element={<AuthLayout/>}>
            <Route path="/login" element={<Login/>}/>
            <Route path="/select-entity" element={<SelectEntity/>}/>
            <Route path="/register" element={<RegisterUser/>}/>
            <Route path="/register-check-email" element={<RegisterCheckEmail/>}/>
            <Route path="/confirm-password" element={<ConfirmUserAccount/>}/>
            <Route path="/register-entity" element={<RegisterEntity/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>
            <Route path="/reset-password" element={<ResetPassword/>}/>
        </Route>
        <Route path="/" element={<BlankLayout/>}>
            <Route path="/404" element={<Error/>}/>
            <Route path="/maintenance" element={<Maintenance/>}/>
            <Route path="/terms-of-use" element={<TermsOfUse/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        </Route>
        <Route path="*" element={<Navigate to="/404"/>}/>
    </Routes>
);

export default Router;
