import {Alert, Button, FormControlLabel, Link, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {stores} from "../../../stores";
import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomCheckbox from "../../../components/forms/theme-elements/CustomCheckbox";
import {useTranslate} from "../../../translations/translate";


const AuthConfirmUserAccount = ({title, token}) => {
    const navigate = useNavigate();
    const translate = useTranslate();
    const [showError, setError] = useState(false);
    const [showSuccess, setSuccess] = useState(false);

    const ConfirmUserPasswordSchema = Yup.object().shape({
        username: Yup.string().min(3, translate("username_too_short")).required(translate("username_required")),
        password: Yup.string().min(8, translate("password_too_short")).required(translate("password_required")),
        confirmPassword: Yup.string()
            .min(8, translate("password_too_short"))
            .oneOf([Yup.ref('password'), null], translate("passwords_must_match"))
            .required(translate("confirm_password_required")),
        readTerms: Yup.boolean().oneOf([true], translate("accept_terms")),
        subscribe: Yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            confirmPassword: '',
            readTerms: false,
            subscribe: true,
        },
        validationSchema: ConfirmUserPasswordSchema,
        onSubmit: (values, {setSubmitting}) => {
            const body = {
                username: values.username,
                password: values.password,
                token: token,
                accepts_marketing: values.subscribe
            }
            stores.userStore
                .confirmAccount(body)
                .then(result => {
                    switch (result) {
                        case true:
                            setSuccess(translate("registration_success_message"));
                            setTimeout(() => {
                                navigate("/login");
                            }, 3000);
                            break;
                        case false:
                        case null:
                            setError(translate('error_try_again'));
                            break;
                        default:
                            console.error('Unexpected result:', result);
                    }
                })
                .catch(error => {
                    console.error('Error during account confirmation:', error);
                    setError(translate('error_try_again'));
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const {
        errors,
        touched,
        values,
        getFieldProps,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
    }
        = formik;

    return (
        <>
            {title ? (
                <Typography variant="headingConsole" color={"secondary"}>
                    {title}
                </Typography>
            ) : null}
            {showError &&
                <Alert variant="filled" severity='error' my={2}>{showError}</Alert>}

            {showSuccess &&
                <Alert variant="filled" severity='success' my={2}>{showSuccess}</Alert>}

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={2} mt={4}>
                        <CustomTextField
                            fullWidth
                            variant="outlined"
                            label={translate('username')}
                            {...getFieldProps('username')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={touched.username && Boolean(errors.username)}
                            helperText={touched.username && errors.username}
                        />
                        <CustomTextField
                            fullWidth
                            variant="outlined"
                            label={translate('create_password')}
                            type="password"
                            {...getFieldProps('password')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                        <CustomTextField
                            fullWidth
                            variant="outlined"
                            label={translate('confirm_password')}
                            type="password"
                            {...getFieldProps('confirmPassword')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                        />
                    </Stack>
                    <FormControlLabel
                        {...getFieldProps('readTerms')}
                        control={<CustomCheckbox/>}
                        label={
                            <>
                                I have read and accept the{' '}
                                <Link href="./terms-of-use" target="_blank" rel="noopener">
                                    {translate('terms_of_use')}
                                </Link>
                                {' '}{translate('and')}{' '}
                                <Link href="./privacy-policy" target="_blank" rel="noopener">
                                    {translate('privacy_policy')}
                                </Link>.
                            </>
                        }
                    />
                    {touched.readTerms && errors.readTerms && (
                        <Typography variant="body2" color="error">&ensp;&ensp;{errors.readTerms}</Typography>
                    )}
                    <FormControlLabel
                        {...getFieldProps('subscribe')}
                        control={<CustomCheckbox defaultChecked/>}
                        label={translate('subscribe_to_newsletter')}
                    />

                    <Stack direction="row" spacing={2} mt={3} justifyContent="flex-end">
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="large"
                            onClick={() => {
                                stores.userStore.logout();
                                navigate("/login");
                            }}
                            type="button"
                        >
                            {translate('cancel')}
                        </Button>
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            size="large"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type="submit"
                        >
                            {translate('register')}
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </>
    );
}
export default AuthConfirmUserAccount;
