import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Box,} from '@mui/material';
import AuthConfirmUserPassword from "../../views/authentication/authForms/AuthConfirmUserAccount";
import {useTranslate} from "../../translations/translate";

const ConfirmUserAccount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const translate = useTranslate();

    // Extract email_confirmation_token from URL
    const searchParams = new URLSearchParams(location.search);
    const emailConfirmationToken = searchParams.get('token');

    // If token isn't found, returns to login
    useEffect(() => {
        if (!emailConfirmationToken) navigate('/login');
    }, []);


    return (
        <Box p={4} sx={{zIndex: '2', maxWidth: 500, width: '100%'}}>
            <AuthConfirmUserPassword
                title={translate('your_last_details')}
                token={emailConfirmationToken}
            />
        </Box>
    );
};

export default ConfirmUserAccount;
